import { useState, useEffect } from "react";
import React, { lazy, Suspense } from "react";
import useVideoLoader from "../src/hooks/useDownloadProgress";
import "./App.css";
import Header from "./components/header";
import ContactUs from "./components/contactUs";
import Footer from "./components/footer";
import LoadingPage from "./components/loading";

const Page1 = lazy(() => import("./components/page1"));
const Page2 = lazy(() => import("./components/page2"));
const Page3 = lazy(() => import("./components/page3"));
const Page4 = lazy(() => import("./components/page4"));

const videoUrls = [
  "https://static.card.casa/external/backgrounds/videos/6-cube-compressed.gif",
  "https://s3.us-east-1.amazonaws.com/static.card.casa/external/backgrounds/videos/9x3-cubes-full.gif",
];

function App() {
  // const { videos, progress, loadedSize, totalSize, loadingComplete } = useVideoLoader(videoUrls);
  const [activeDiamonds, setActiveDiamonds] = useState([1]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const pages = document.querySelectorAll(".page-section");
    let scrolledPages = [];

    pages.forEach((page, index) => {
      const rect = page.getBoundingClientRect();
      if (rect.top <= window.innerHeight * 0.6) {
        scrolledPages.push(index + 1);
      }
    });

    setActiveDiamonds(scrolledPages);
  };

  const scrollToPage = (pageNumber) => {
    const page = document.getElementById(`page-${pageNumber}`);
    page.scrollIntoView({ behavior: "smooth" });
  };

  // if (!loadingComplete) {
  //   return <LoadingPage progress={progress} loadedSize={loadedSize} totalSize={totalSize} />;
  // }

  return (
    <div className="page">
      {/* <Suspense fallback={<LoadingPage progress={progress} loadedSize={loadedSize} totalSize={totalSize} />}> */}
        <Header />
        <div id="page-1" className="page-section fade-in">
          <Page1 videoSrc={videoUrls[1]} />
        </div>
        <div id="page-2" className="page-section fade-in">
          <Page2 videoSrc={videoUrls[0]} />
        </div>
        <div id="page-3" className="page-section fade-in"><Page3 /></div>
        <div id="page-4" className="page-section fade-in"><Page4 /></div>
        <div id="page-5" className="page-section fade-in">
          <ContactUs videoSrc={videoUrls[1]} />
        </div>

        <Footer />
        <div className="status-diamonds">
          <div className="line-container">
            <div
              className="connecting-line"
              style={{ height: `${(activeDiamonds.length - 1) * 55}px` }}
            ></div>
          </div>
          {[1, 2, 3, 4, 5].map((pageNumber) => (
            <div
              key={pageNumber}
              className={`diamond ${activeDiamonds.includes(pageNumber) ? "active" : ""}`}
              onClick={() => scrollToPage(pageNumber)}
            ></div>
          ))}
        </div>
      {/* </Suspense> */}
    </div>
  );
}

export default App;
