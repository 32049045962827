import React from "react";
import "./Footer.css";

const Footer = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  };
  return (
    <footer className="footer">
      <div className="footer-left">
        <h2 className="footer-title">CRYPTONET</h2>
        <p className="footer-text">
          The world's first<br/>  decentralized internet,<br/>  empowering you with freedom <br/>  and privacy.
        </p>
        <button className="contact-button">Contact Us</button>
      </div>
      <div className="blue-border">
      <div className="footer-right" style={{ minHeight: "200px" }}>
        <div className="footer-right-content">
          <div className="footer-right-left">
            <h3 className="footer-subtitle">CONTACT</h3>
            <p>+1 (916) 796-7815</p>
            <p>rijul@cryptonet.technology</p>
          </div>
          <div className="footer-right-right">
            <ul className="footer-links">
            <div onClick={() => scrollToSection('page-1')}>Home</div>
              <div><a href="https://www.card.casa">Card Casa</a></div>
              <div onClick={() => scrollToSection('page-5')}>Contact Us</div>

            </ul>
          </div>
        </div>
        <div className="right-bottom">
          Managed by Rijul Luman 
        </div>
      </div>
      </div>

    </footer>
  );
};

export default Footer;
