import { useState, useEffect } from 'react';
import './header.css';

function Header() {
  const [scrollDirection, setScrollDirection] = useState('down');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let lastScrollY = 0;
 
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrollDirection('down'); // Scrolling down
      } else {
        setScrollDirection('up'); // Scrolling up
      }
      lastScrollY = window.scrollY <= 0 ? 0 : window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to scroll to sections
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setIsMenuOpen(false); // Close menu after clicking
  };

  return (
    <div className={`navbar ${scrollDirection === 'down' ? 'hidden' : ''}`}>
      <div className="heading">Crptonet</div>

      {/* Menu Toggle Button */}
      <div className="menu-icon" onClick={() => setIsMenuOpen((prev) => !prev)}>
        ☰ {/* Hamburger icon */}
      </div>

      {/* Navigation Menu */}
      <div className={`go-to ${isMenuOpen ? 'open' : ''}`}>
        <div onClick={() => scrollToSection('page-1')}>Home</div>
        <div onClick={() => scrollToSection('page-4')}>TimeLine</div>
        <div><a href="https://www.card.casa">Card-Casa</a></div>
        <div onClick={() => scrollToSection('page-5')}>Contact Us</div>
      </div>
    </div>
  );
}

export default Header;
