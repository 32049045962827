import React, { useState } from 'react';
import './contactUs.css';

const ContactUs = ({ videoSrc }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [showPopup, setShowPopup] = useState(false);

  // Handle input change & persist form data
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Show error popup on form submit
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page reload
    setShowPopup(true);
  };

  return (
    <div className="contact-page">
      <div className="contact-left">
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <label>Full Name</label>
            <input
              type="text"
              name="fullName"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
            />

            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
            />

            <label>Phone Number</label>
            <input
              type="tel"
              name="phone"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
            />

            <div className="file-upload">
              <span>📂</span> 
              <a href="#">Click here</a> to Attach File (Less Than 50MB)
            </div>

            <label>Message</label>
            <textarea
              name="message"
              placeholder="Write your message..."
              value={formData.message}
              onChange={handleChange}
            ></textarea>

            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>

      <div className="contact-right">
        <img className="background-image3" src={videoSrc} alt="Background Loading..." />
        <p>Join us in discovering the exciting possibilities of a decentralized future!</p>
      </div>

      {/* Pop-up Modal */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>Error Processing Request, kindly contact us via email at <strong>rijul@cryptonet.technology</strong></p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
